import React, { Dispatch, SetStateAction, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { fieldmap } from "./fieldmap";
import { useSelector } from "react-redux";

import graySamsar from "../../assets/images/samsaricon-gray.png";
import blueSamsar from "../../assets/images/samsaricon-blue.png";
import checkIcon from "../../assets/svg/check-icon.svg";
import { handleStep } from "../../reducers/inspectionReducer";
import { useAppDispatch } from "../../store/rtctype";
import { Field } from "./types";
import { useLocation } from "react-router-dom";
import { getPermission } from "../../service/apiService";
import { handlePermissionUser } from "../../reducers/authReducer";

export const ProgressSidebar = ({
  type,
  open,
  setOpen,
}: {
  type: string;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const formData = useSelector((state: any) => state.inspection).formData;

  const { user } = useSelector((state: any) => state.user);
  const { permission, loading } = useSelector((state: any) => state.user);
  const location = useLocation();
  const reduxStep = useSelector((state: any) => state.inspection).step;
  const [updatePermission, setUpdatePermission] = React.useState(false);
  // useEffect(()=>{
  //   setUpdatePermission(false)
  // },[])
  //
  useEffect(() => {
    if (
      user?.username === "operator" ||
      user?.username === "myohanna" ||
      user?.username === "hgavi"
    ) {
      // const obj = {...fieldmap}
      delete fieldmap.step_14;
      //
    }
  }, [user]);
  React.useEffect(() => {
    if (!permission) {
      const authToken = localStorage.getItem("accessToken") || "";
      // setLoader(true);
      // dispatch(handleLoading(true));
      getPermission(authToken)
        .then((obj: any) => {
          // console.log("%c Line:53 🍇 obj", "color:#93c0a4", obj);
          if (obj?.success) {
            // dispatch(handlePermissionUser(obj?.data?.role?.permission));
            if (
              obj?.data?.role?.permission &&
              obj?.data?.role?.permission?.length > 0
            ) {
              dispatch(handlePermissionUser(obj?.data?.role?.permission));
              delete fieldmap.step_16;
              delete fieldmap.step_14;
              delete fieldmap.step_1;
              delete fieldmap.step_2;
              delete fieldmap.step_3;
              delete fieldmap.step_4;
              delete fieldmap.step_18;
              delete fieldmap.step_11;
              delete fieldmap.step_12;
              delete fieldmap.step_13;
              delete fieldmap.step_20;
              delete fieldmap.step_21;
              delete fieldmap.step_22;
              delete fieldmap.step_15;
              delete fieldmap.step_19;
              delete fieldmap.step_23;
              delete fieldmap.step_24;
              delete fieldmap.step_25;
              delete fieldmap.step_26;
              delete fieldmap.step_27;
              delete fieldmap.step_28;
              delete fieldmap.step_29;
              delete fieldmap.step_30;
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Schedular tool"
                )?.read
              ) {
                delete fieldmap.step_16;
              } else {
                fieldmap.step_16 = {
                  title: "Scheduler Dashboard",
                  fields: [
                    {
                      key: "schedule",
                      name: "Scheduler Dashboard",
                      type: "field",
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "KPI"
                )?.read
              ) {
                delete fieldmap.step_14;
              } else {
                fieldmap.step_14 = {
                  title: "KPI Dashboard",
                  fields: [
                    {
                      key: "kpi",
                      name: "Key Performance Indicator",
                      type: "field",
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Operation"
                )?.read
              ) {
                delete fieldmap.step_1;
                delete fieldmap.step_2;
                delete fieldmap.step_3;
                delete fieldmap.step_4;
                delete fieldmap.step_11;
                delete fieldmap.step_12;
                delete fieldmap.step_18;
              } else {
                fieldmap.step_1 = {
                  title: "Incoming Lot",
                  fields: [
                    {
                      key: "identifier",
                      name: "Scan Lot ID",
                      type: "field",
                    },
                    {
                      key: "oem",
                      name: "OEM",
                      type: "select",
                      option: [],
                      optionVal: "code",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Add Another",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_2 = {
                  title: "Incoming Package Inspection",
                  view_media: [
                    {
                      type: "image",
                      key: "pallet_image",
                      name: "Sample Pallet Image",
                      src: "pallet_3.png",
                    },
                  ],
                  upload_media: [
                    {
                      type: "image",
                      key: "image",
                      name: "Uploaded Pallet Image",
                    },
                  ],
                  form_content: [
                    { name: "Pallet Number", key: "identifier" },
                    { name: "Pallet Weight", key: "weight" },
                  ],
                  fields: [
                    {
                      key: "shipment_lot",
                      name: "Scan Lot ID",
                      description: "Scan Lot ID",
                      type: "select",
                      option: [],
                      optionVal: "identifier",
                    },
                    {
                      key: "identifier",
                      name: "Pallet ID",
                      description: "Desc",
                      type: "field",
                    },
                    {
                      key: "weight",
                      name: "Pallet Weight (lbs)",
                      type: "field",
                      validate: {
                        range: [300, 3000],
                      },
                    },
                    {
                      key: "image",
                      name: "Pallet Image",
                      type: "file",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Add Another",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_3 = {
                  title: "Inbound WIP",
                  fields: [
                    {
                      key: "shipment_lot",
                      name: "Incoming Lot ID",
                      type: "select",
                      option: [],
                      optionVal: "identifier",
                    },
                    {
                      key: "pallet",
                      name: "Scan Pallet ID",
                      type: "select",
                      option: [],
                      optionVal: "identifier",
                    },
                    {
                      key: "oem",
                      name: "Select OEM",
                      type: "select",
                      option: ["Nissan", "Toyota", "Honda"],
                      optionVal: "code",
                    },
                    {
                      key: "oem_model",
                      name: "Select Model",
                      type: "select",
                      option: [
                        "Leaf Gen 1",
                        "Leaf Gen 2",
                        "Leaf Gen 3",
                        "Leaf Gen 4",
                      ],
                      optionVal: "code",
                    },
                    {
                      key: "storage_bin",
                      name: "Scan Inbound WIP Bin ID",
                      type: "field",
                    },
                    {
                      key: "module_count",
                      name: "Enter number of modules to be placed in Bin",
                      type: "field",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Add Another",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };

                fieldmap.step_4 = {
                  title: "Daily Prep",
                  fields: [
                    {
                      key: "number_of_units",
                      name: "Number of Unit",
                      type: "field",
                    },
                    {
                      key: "oem",
                      name: "Select OEM",
                      type: "select",
                      option: ["Nissan", "Toyota", "Honda"],
                      optionVal: "code",
                    },
                    {
                      key: "oem_model",
                      name: "Select Model",
                      type: "select",
                      option: [
                        "Leaf Gen 1",
                        "Leaf Gen 2",
                        "Leaf Gen 3",
                        "Leaf Gen 4",
                      ],
                      optionVal: "code",
                    },
                    {
                      key: "bin_id",
                      name: "Bin Id",
                      type: "field",
                    },
                    {
                      key: "kwy_gen",
                      name: "Generate Module Serial Number",
                      type: "custom",
                      action: "generate_serial_number",
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_18 = {
                  title: "HIS Progress",
                  fields: [
                    {
                      key: "kpi1",
                      name: "Scan Module ID",
                      type: "HIS",
                    },
                  ],
                };
                fieldmap.step_11 = {
                  title: "Sorting & Binning Process",
                  fields: [
                    {
                      key: "module_id",
                      name: "Scan Module ID",
                      type: "field",
                    },
                  ],
                };

                fieldmap.step_12 = {
                  title: "Module Packaging",
                  fields: [
                    {
                      key: "number_of_packs",
                      name: "Enter number of packs to be shipped",
                      type: "field",
                    },
                    {
                      key: "oem",
                      name: "Select Module OEM",
                      type: "select",
                      option: ["Nissan", "Toyota", "Honda"],
                      optionVal: "code",
                    },
                    {
                      key: "oem_model",
                      name: "Select Module Model",
                      type: "select",
                      option: [
                        "Leaf Gen 1",
                        "Leaf Gen 2",
                        "Leaf Gen 3",
                        "Leaf Gen 4",
                      ],
                      optionVal: "code",
                    },
                    {
                      key: "modules_per_pack",
                      name: "Enter number of modules/pack",
                      type: "field",
                    },
                    {
                      key: "soh_high",
                      name: "Enter SOH High for product",
                      type: "field",
                    },
                    {
                      key: "soh_low",
                      name: "Enter SOH Low for product",
                      type: "field",
                    },
                    {
                      key: "assembly_contractor",
                      name: "Select Assembly Contractor",
                      type: "select",
                      option: ["Alchemy", "Precise Connections"],
                    },
                    {
                      key: "assembly_location",
                      name: "Select Assembly location",
                      type: "select",
                      option: ["HTX", "DTX"],
                    },
                    {
                      key: "customer",
                      name: "Customer Name",
                      type: "select",
                      option: ["ECAM", "Wanco", "NSI", "Ver-Mac"],
                    },
                    {
                      key: "fieldSegment",
                      name: "Field Segment",
                      type: "select",
                      option: ["Security Trailer", "Light Trailer"],
                    },
                    {
                      key: "voltage",
                      name: "Voltage",
                      type: "select",
                      option: ["15", "29", "48", "50"],
                    },
                    {
                      key: "bill_of_material",
                      name: "Bill Of Material(BOM) ID",
                      type: "select",
                      option: ["001", "002", "003", "004"],
                    },
                    {
                      key: "submit",
                      name: "",
                      type: "submit",
                      sidenav: false,
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Report"
                )?.read
              ) {
                delete fieldmap.step_13;
              } else {
                fieldmap.step_13 = {
                  title: "Report",
                  fields: [
                    {
                      key: "gen_report",
                      name: "Publish",
                      type: "custom",
                      action: "publish_report",
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Passport"
                )?.read
              ) {
                delete fieldmap.step_15;
              } else {
                fieldmap.step_15 = {
                  title: "Passport",
                  fields: [
                    {
                      key: "gen_passport",
                      name: "Publish",
                      type: "custom",
                      action: "publish_passport",
                    },
                  ],
                };
              }

              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Bulk Report"
                )?.read
              ) {
                delete fieldmap.step_20;
              } else {
                fieldmap.step_20 = {
                  title: "Report Bulk Download",
                  fields: [
                    {
                      key: "bulk",
                      name: "Bulk download",
                      type: "field",
                    },
                  ],
                };
              }

              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Vendor"
                )?.read
              ) {
                delete fieldmap.step_19;
              } else {
                fieldmap.step_19 = {
                  title: "Vendor",
                  view_media: [
                    {
                      type: "image",
                      key: "vendor_image",
                      name: "Sample Pallet Image",
                      src: "new1.jpeg",
                    },
                  ],
                  upload_media: [
                    {
                      type: "image",
                      key: "vendor_image",
                      name: "Uploaded Pallet Image",
                    },
                  ],
                  fields: [
                    {
                      key: "packId",
                      name: "Pack ID",
                      type: "select",
                      option: [""],
                      optionVal: "packId",
                    },
                    {
                      key: "rejected_moduleId",
                      name: "Reject Pack ID",
                      type: "field",
                    },
                    {
                      key: "replace_module",
                      name: "Add/replace Pack ID",
                      type: "field",
                    },
                    {
                      key: "vendor_image",
                      name: "Upload picture",
                      type: "file",
                    },
                    {
                      key: "vendor_excel",
                      name: "Upload factory acceptance test",
                      type: "excel",
                    },
                    {
                      key: "vendor_save",
                      name: "Continue",
                      type: "custom",
                      action: "generate_serial_number",
                      sidenav: false,
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Assembler"
                )?.read
              ) {
                delete fieldmap.step_21;
              } else {
                fieldmap.step_21 = {
                  title: "Assembler Portal",
                  fields: [
                    {
                      key: "assembler",
                      name: "Assembler Portal",
                      type: "field",
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "Customer"
                )?.read
              ) {
                delete fieldmap.step_22;
              } else {
                fieldmap.step_22 = {
                  title: "Customer Portal",
                  fields: [
                    {
                      key: "customer",
                      name: "Customer Portal",
                      type: "field",
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "QIS Operation"
                )?.read
              ) {
                delete fieldmap.step_23;
                delete fieldmap.step_24;
                delete fieldmap.step_25;
                delete fieldmap.step_26;
                delete fieldmap.step_27;
                delete fieldmap.step_28;
                delete fieldmap.step_29;
              } else {
                fieldmap.step_23 = {
                  title: "Module Inspection",
                  fields: [
                    {
                      key: "QIS",
                      name: "Scan Pack ID",
                      type: "fieldQIS",
                    },
                    {
                      key: "voltageDifferenceOne",
                      name: "Enter voltage cell One.",
                      type: "field",
                    },
                    {
                      key: "voltageDifferenceTwo",
                      name: "Enter voltage cell Two.",
                      type: "field",
                    },
                    {
                      key: "voltageDifferenceThree",
                      name: "Enter voltage cell Three.",
                      type: "field",
                    },
                    {
                      key: "voltageDifferenceFour",
                      name: "Enter voltage cell Four.",
                      type: "field",
                    },
                    {
                      key: "heatShrinkOnTerminalStuds",
                      name: "Heat shrink on terminal studs.",
                      type: "checkbox",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Save",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_24 = {
                  title: "QIS 1 : Assembly 1",
                  fields: [
                    {
                      key: "QIS",
                      name: "Scan Pack ID",
                      type: "fieldQIS",
                    },
                    {
                      key: "applyHeating",
                      name: "Apply heating element to each side of module",
                      type: "checkbox",
                    },
                    {
                      key: "fastenModulesWithTorque",
                      name: "Fasten modules with mounting brackets. Torque to 11.4 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "threadZiptiesTopMountingPlate",
                      name: "Thread three zip ties and secure top plate to battery stack. Apply anti-seize and torque to 4.5 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "mountActiveEqualizerAndParallelModule",
                      name: "Mount Active Equalizer and Parallel Module. Apply Loctite 242 and torque to 0.6 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "applyQR",
                      name: "Apply QR code sticker to top plate.",
                      type: "checkbox",
                    },
                    {
                      key: "mountBmsWithTape",
                      name: "Mount BMS and heating module with VHB tape.",
                      type: "checkbox",
                    },
                    {
                      key: "mountHeaterModule",
                      name: "Connect heating elements to heating module. Torque to 1.2 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "torqueDownHeaterModule",
                      name: "Mount terminal protection base and bus bars.",
                      type: "checkbox",
                    },
                    {
                      key: "wiringHeaterModule",
                      name: "Secure wiring to terminals. Apply Loctite 242 to bolts. Torque top and bottom rows to 5.5 N-m and middle row to 1.2 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "rotatePackPlaceTerminalProtection",
                      name: "Apply Loctite 242 and hand-tighten screws to secure terminal protection cap.",
                      type: "checkbox",
                    },
                    {
                      key: "voltageDifference",
                      name: "Enter voltage difference from Daly Detector of Cable Sequence device",
                      type: "field",
                    },
                    {
                      key: "connectParallelModuleAndBms",
                      name: "Plug in and zip-tie remaining wiring",
                      type: "checkbox",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Save",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_25 = {
                  title: "QIS 2 : Calibration",
                  fields: [
                    {
                      key: "QIS",
                      name: "Scan Pack ID",
                      type: "fieldQIS",
                    },
                    {
                      key: "tapeBmsThermistorToPack",
                      name: "Tape thermistor to back of pack",
                      type: "checkbox",
                    },
                    {
                      key: "connectEqualizerAndBmsToYHarness",
                      name: "Connect Active Equalizer, then BMS to Y-Harness.",
                      type: "checkbox",
                    },
                    {
                      key: "confirmVoltageTerminalToTerminal",
                      name: "Enter voltage from bottom left negative to bottom right positive terminal.",
                      type: "field",
                    },
                    {
                      key: "configureBms",
                      name: "Upgrade BMS firmware and apply configuration.",
                      type: "checkbox",
                    },
                    {
                      key: "confirmVoltageWireToWire",
                      name: "Confirm voltage by testing wire to wire.",
                      type: "field",
                    },
                    {
                      key: "configureActiveEqualizer",
                      name: "Configure Active Equalizer.",
                      type: "checkbox",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Save",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_26 = {
                  title: "QIS 3 : Assembly 2",
                  fields: [
                    {
                      key: "QIS",
                      name: "Scan Pack ID",
                      type: "fieldQIS",
                    },
                    {
                      key: "placeCableMounts",
                      name: "QC: harness connections, zipties, QR code, hardware secure, thermistor taped, etc.",
                      type: "checkbox",
                    },
                    {
                      key: "lowerPackIntoEnclosure",
                      name: "Lower/secure pack to enclosure. Apply anti-seize and torque to 10.0 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "confirmLidGasketMounted",
                      name: "Ensure lid has CANBUS connector, vent, and both Rehnotec terminals secured with Loctite 242 on screws.",
                      type: "checkbox",
                    },
                    {
                      key: "fastenWiresWithZipties",
                      name: "Connect white and black wires to heating module.",
                      type: "checkbox",
                    },
                    {
                      key: "secureLidOnEnclosure1",
                      name: "Mount lid gasket.",
                      type: "checkbox",
                    },
                    {
                      key: "ipqcWireConnectionsLugAndCan485",
                      name: "Mount two white wires to red terminal and three black wires to black terminal. Apply Loctite 242 to bolts.",
                      type: "checkbox",
                    },
                    {
                      key: "connectCan485WiresToBms",
                      name: "Connect CAN&485 wires from lid to BMS.",
                      type: "checkbox",
                    },
                    {
                      key: "ipqcModuleConnectionsZiptiesQrCode",
                      name: "QC: 6 bolts, wires to terminals, CANBUS conection, gasket, etc.",
                      type: "checkbox",
                    },
                    {
                      key: "securePackToEnclosure",
                      name: "Secure lid to enclosure. Apply anti-seize and torque to 8.0 N-m.",
                      type: "checkbox",
                    },
                    {
                      key: "applyQrCodeToTopMountingPlate",
                      name: "Apply three labels to top of lid.",
                      type: "checkbox",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Save",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_27 = {
                  title: "QIS 4 : Cycling",
                  fields: [
                    {
                      key: "QIS",
                      name: "Scan Pack ID",
                      type: "fieldQIS",
                    },
                    {
                      key: "cyclePack",
                      name: "Charge pack to 100%",
                      type: "checkbox",
                    },
                    {
                      key: "confirmedFreezerTestConducted",
                      name: "Conduct deep freeze heater test",
                      type: "checkbox",
                    },
                    {
                      key: "packQcCapacityVoltageCanComm",
                      name: "Conduct DK cycle, analyze results, and save detailed report.",
                      type: "checkbox",
                    },
                    {
                      key: "uploadDataFromCycler",
                      name: "Upload data from DK cycle",
                      type: "excel",
                    },
                    {
                      key: "next_step",
                      type: "reset",
                      name: "Save",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
                fieldmap.step_28 = {
                  title: "Pack Packaging",
                  view_media: [
                    {
                      type: "image",
                      key: "photoStationTop",
                      name: "Photograph top of pack",
                      src: "new1.jpeg",
                    },
                    {
                      type: "image",
                      key: "photoStationLeft",
                      name: "Photograph Samsar side of pack",
                      src: "new1.jpeg",
                    },
                    {
                      type: "image",
                      key: "photoStationRight",
                      name: "Photograph warning label side of pack",
                      src: "new1.jpeg",
                    },
                  ],
                  upload_media: [
                    {
                      key: "photoStationTop",
                      name: "Photograph top of pack",
                      type: "image",
                    },
                    {
                      key: "photoStationLeft",
                      name: "Photograph Samsar side of pack",
                      type: "image",
                    },
                    {
                      key: "photoStationRight",
                      name: "Photograph warning label side of pack",
                      type: "image",
                    },
                  ],
                  fields: [
                    {
                      key: "QIS",
                      name: "Scan Pack ID",
                      type: "fieldQIS",
                    },
                    {
                      key: "applyManufacturedDateLabel",
                      name: "Apply warning label with manufacturing date.",
                      type: "checkbox",
                    },
                    {
                      key: "wipeDownEnclosure",
                      name: "Wipe down entire enclosure.",
                      type: "checkbox",
                    },
                    {
                      key: "attachRedAndBlackLugCovers",
                      name: "Attach red and black lug covers.",
                      type: "checkbox",
                    },
                    {
                      key: "photoStationTop",
                      name: "Photograph top of pack",
                      type: "file",
                    },
                    {
                      key: "photoStationLeft",
                      name: "Photograph Samsar side of pack",
                      type: "file",
                    },
                    {
                      key: "photoStationRight",
                      name: "Photograph warning label side of pack",
                      type: "file",
                    },
                    {
                      key: "tapeBoxAndLabel",
                      name: "Box finished pack with packing foam, Board number label, and three hazardous labels.",
                      type: "checkbox",
                    },

                    {
                      key: "next_step",
                      type: "reset",
                      name: "Save",
                      hidden: true,
                      sidenav: false,
                    },
                  ],
                };
              }
              if (
                !obj?.data?.role?.permission?.find(
                  (module: any) => module?.module === "QIS KPI"
                )?.read
              ) {
                delete fieldmap.step_29;
              } else {
                fieldmap.step_29 = {
                  title: "Assembler KPI ",
                  fields: [
                    {
                      key: "kpi",
                      name: "Key Performance Indicator",
                      type: "field",
                    },
                  ],
                };
              }
              // if (
              //   !obj?.data?.role?.permission?.find(
              //     (module: any) => module?.module === "QIS Report"
              //   )?.read
              // ) {
              //   delete fieldmap.step_30;
              // } else {
              // fieldmap.step_30 = {
              //   title: "QIS Report",
              //   fields: [
              //     {
              //       key: "qis_passport",
              //       name: "Publish",
              //       type: "custom",
              //       action: "QIS_report",
              //     },
              //   ],
              // };
              // }

              const moduleSteps = [
                { module: "QIS Operation", step: "step_23" },
                // { module: "QIS Report", step: "step_30" },
                { module: "Schedular tool", step: "step_16" },
                { module: "Passport", step: "step_15" },
                { module: "Report", step: "step_13" },
                { module: "Bulk Report", step: "step_20" },
                { module: "Assembler", step: "step_21" },
                { module: "QIS KPI", step: "step_29" },
                { module: "Customer", step: "step_22" },
                { module: "Vendor", step: "step_19" },
                { module: "Operation", step: "step_1" },
                { module: "KPI", step: "step_14" },
              ];

              moduleSteps.forEach(({ module, step }) => {
                if (
                  obj?.data?.role?.permission?.find(
                    (perm: any) => perm?.module === module
                  )?.read
                ) {
                  dispatch(handleStep(step));
                }
              });
            }
          }
        })
        .catch((e) => {
          console.log("%c Line:56 🍇 e", "color:#42b983", e);
        })
        .finally(() => {
          // dispatch(handleLoading(false));
          // setLoader(false);
        });
    }
  }, [location.pathname]);

  const dispatch = useAppDispatch();

  const handleToStep = (step: string) => {
    // update local storage with current step
    localStorage.setItem("step", step);
    dispatch(handleStep(step));
  };

  return (
    <div className="sm-colm-3">
      {!loading &&
        formData &&
        Object.keys(fieldmap).map((step, index) => {
          return (
            <>
              <Accordion
                key={index}
                className="sm-dark-accordian"
                expanded={reduxStep === step}
                onClick={() => {
                  handleToStep(step);
                  if (type === "Drawer" && setOpen) {
                    setOpen(false);
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <img src={checkIcon} alt="" />
                  <Typography>{fieldmap[step]?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {step === "step_23" ||
                  step === "step_24" ||
                  step === "step_25" ||
                  step === "step_26" ||
                  step === "step_27" ||
                  step === "step_28" ? (
                    <List component="div">
                      {fieldmap[step]?.fields
                        ?.slice(0, 1)
                        ?.map((field: Field, i: number) =>
                          field.sidenav !== false ? (
                            <>
                              <ListItemButton key={i}>
                                <ListItemText primary={field?.name} />
                                <ListItemIcon>
                                  {formData[step] &&
                                  formData[step][field?.key] ? (
                                    <img src={blueSamsar} />
                                  ) : (
                                    <img src={graySamsar} />
                                  )}
                                </ListItemIcon>
                              </ListItemButton>
                            </>
                          ) : null
                        )}
                    </List>
                  ) : (
                    <List component="div">
                      {fieldmap[step]?.fields?.map((field: Field, i: number) =>
                        field.sidenav !== false ? (
                          <>
                            <ListItemButton key={i}>
                              <ListItemText primary={field?.name} />
                              <ListItemIcon>
                                {formData[step] &&
                                formData[step][field?.key] ? (
                                  <img src={blueSamsar} />
                                ) : (
                                  <img src={graySamsar} />
                                )}
                              </ListItemIcon>
                            </ListItemButton>
                          </>
                        ) : null
                      )}
                    </List>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
    </div>
  );
};
